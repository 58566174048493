import React, { useState } from "react";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism-okaidia.css'; //Example style, you can use another

const code = `class ATest:
    def __init__(self):
        pass
  `;

// const hightlightWithLineNumbers = (input, language) =>
//   highlight(input, language)
//     .split("\n")
//     .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
//     .join("\n");

const CodeView = () => {
    const [codeValue, setCodeValue] = useState(code);
    return (
        <Editor
          value={codeValue}
          onValueChange={code => setCodeValue(code)}
          highlight={code => highlight(code, languages.python)}
          padding={10}
          textareaId="codeArea"
          className="editor"
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 18,
            outline: 0
          }}
        />
      );
};

export default CodeView;