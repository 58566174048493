import React, { useRef, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import Banner from './Banner';
import RenderMenuList from './RenderMenuList';
import ShowPortal from './ShowPortal';
const menuIcon = <FontAwesomeIcon icon={faBars} />;
const portal = document.getElementById('portal');

const Navbar = () => {
  const inputRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [itemsCount, setItemsCount] = useState(0);
  const menuItems = document.querySelector('.render-menu-list');
  useEffect(() => {
    setItemsCount(+menuItems?.childElementCount);
  }, [menuItems]);
  return (
    <nav
      className={` bg-greyish flex text-white font-Poppins items-center space-x-2  w-screen gap-10 md:gap-32 lg:gap-1  relative navbar `}
    >
      <div className="px-10 md:px-20">
        <Banner />
      </div>

      {showMenu && (
        <ShowPortal
          windowSize={windowSize}
          setWindowSize={setWindowSize}
          setShowMenu={setShowMenu}
        />
      )}
      <ul
        className={`hidden absolute -right-1/4  
        ${
          itemsCount === 6 ? 'lg:-translate-x-96' : 'lg:-translate-x-52'
        } lg:flex lg:gap-10  md:w-11/12 lg:w-4/6  text-lg items-center my-0 `}
      >
        <RenderMenuList />
      </ul>

      <div className="absolute flex items-center p-2 rounded-lg right-4 md:right-10">
        <div
          ref={inputRef}
          className="flex items-center justify-center w-0 mr-5 text-black cursor-pointer search-input rounded-2xl"
        ></div>
        <div
          className="text-3xl transition-all duration-300 cursor-pointer hover:rotate-180 hover:origin-center lg:hidden"
          onClick={() => {
            setShowMenu(true);
            portal.classList.add('active');
          }}
        >
          <span className="h-full transition-all duration-300 hover:rotate-180">
            {menuIcon}
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;