import React, { useEffect } from 'react';
import NavBar from '../component/NavBar';
import './Team.css';

// import { faGithub } from '@fortawesome/free-brands-svg-icons';
// // import prakash from '../assets/images/Prakash.jpg';
// // import suraj from '../assets/images/Suraj.jpeg';
// // import marcellin from '../assets/images/Marcellin.jpg';
// // import saugat from '../assets/images/Saugat.jpg';

import prakash from '../assets/images/Prakash1.png';
import suraj from '../assets/images/Suraj1.png';
import marcellin from '../assets/images/Marcellin1.png';
import saugat from '../assets/images/Saugat1.png';

// const Team = () => {
//   return (
//     <>
//       <NavBar />
//       <div className="flex flex-col flex-wrap items-center justify-center min-h-screen gap-12 lg:gap-x-60 lg:flex-row bg-greyish">
//         {people.map((person) => {
//           return (
//             <div
//               key={person.id}
//               className="w-5/6 transition-all duration-100 ease-in-out hover:border-2 border-orangish md:w-1/2 lg:w-1/4"
//             >
//               <img
//                 src={person.imageLink}
//                 alt={person.name}
//                 className="w-full"
//               />
//               {person.name}
//             </div>
//           );
//         })}
//       </div>
//     </>
//   );
// };
// export default Team;

const people = [
  {
    id: 1,
    imageLink: prakash,
    name: 'Prakash Manandhar',
    title: 'CEO',
    // socialLink
    description: `Prakash has a multidisciplinary background with a Ph.D. in Bioengineering, an MBA, and MS. in Engineering System Design and Management. He has worked in various capacities in the engineering industry in the last two decades, starting in software engineering and then transitioning to medical device engineering, consumer electronics, and artificial intelligence. He has publications in medical imaging, ultrasonic surgical devices, flexible electronics, and multidisciplinary optimization. Dr. Manandhar holds patents in ultrasonic surgical instruments and artificial intelligence. He is affiliated with the Massachusetts Institute of Technology global teamwork lab, where he is researching agent-based modeling and simulation for optimizing and measuring engineering teamwork. In his various roles, including Head of Engineering at startups and Engineering Lead at established companies, he has championed using AI, simulation, and modeling to speed up engineering product development.\n On the fun side, Prakash is a fan of the city simulation and turn-based strategy genre of computer games.\n He is passionate about bringing agent-based and finite element modeling, simulation, and generative AI technology to speed up and democratize engineering product development.`,
  },
  {
    id: 2,
    imageLink: suraj,
    name: 'Suraj Adhikari',
    title: 'CTO',
    description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Temporibus, dolor vel! Maiores perferendis aspernatur incidunt quam facilis. Quae fuga obcaecati accusantium non sunt mollitia amet dolores eum similique rerum? Tempore.`,
  },
  {
    id: 3,
    imageLink: marcellin,
    name: 'Marcellin Feasson',
    title: 'COO',
    description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Temporibus, dolor vel! Maiores perferendis aspernatur incidunt quam facilis. Quae fuga obcaecati accusantium non sunt mollitia amet dolores eum similique rerum? Tempore.`,
  },
  {
    id: 4,
    imageLink: saugat,
    title: 'Engineer',
    name: 'Saugat Poudel',
    description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Temporibus, dolor vel! Maiores perferendis aspernatur incidunt quam facilis. Quae fuga obcaecati accusantium non sunt mollitia amet dolores eum similique rerum? Tempore.`,
  },
];
const Team = () => {
  useEffect(() => {
    const contents = document.querySelectorAll('.project');
    console.log(contents);
    function showBox() {
      const triggerBottom = (window.innerHeight / 5) * 4; //we are setting the trigger point; as we enter here event should fire up
      contents.forEach((content) => {
        const boxTop = content.getBoundingClientRect().top;
        if (boxTop < triggerBottom) {
          content.classList.add('show');
        }
      });
    }
    window.addEventListener('scroll', showBox);
    return () => window.removeEventListener('scroll', showBox);
  });
  return (
    <>
      <NavBar />
      <div
        id="people"
        className="w-screen min-h-screen p-10 text-white lg:h-auto md:p-20 bg-greyish"
      >
        <h1 className="flex flex-wrap items-center justify-center w-full h-auto mb-10 text-5xl font-bold leading-tight magic drop-shadow-md bg-clip-text md:leading-snug text-clip">
          <h1>About Us</h1>
        </h1>

        <div className="flex flex-col items-center justify-center people gap-y-5 gap-x-5 ">
          {people.map((person, index) => {
            return (
              <div
                className={`${
                  index === 1 || index === 3 ? 'md:flex-row-reverse ' : ''
                }flex flex-col md:flex-row items-center justify-center relative   w-full person`}
              >
                <div className="flex items-start justify-center w-full my-4 overflow-hidden rounded-3xl lg:w-1/3 md:w-full h-96 hover:shadow-xl hover:shadow-slate-800">
                  <img
                    // src="https://picsum.photos/1280"
                    src={person.imageLink}
                    alt=""
                    srcSet=""
                    className="object-cover object-right transition-all duration-500 ease-in-out w-96 h-96 hover:scale-110 lg:object-cover"
                  />
                </div>
                <div
                  className={`right  md:h-auto -translate-y-1/4  opacity-70 md:opacity-100 md:-translate-x-10 ${
                    (index === 1 || index === 3) && 'md:translate-x-5'
                  } md:translate-y-0 rounded-3xl  flex flex-col items-center bg-black p-5  shadow-xl  hover:shadow-xl hover:shadow-slate-900 duration-300 w-full lg:w-1/2  `}
                >
                  <h2 className="my-3 text-xl font-bold ">
                    <span className="text-white">{person.name} , </span>
                    <span className="text-orangish">{person.title}</span>
                  </h2>
                  <section className="text-slate-200">
                    {/* {person.description.split(" ").map(word=>word==="\n"?'':<br></br>)} */}
                    {person.description.split('\n').map((line, index) => (
                      <>
                        <p key={index}>{line}</p>
                        <br />
                      </>
                    ))}
                  </section>
                  <div className="flex items-start w-full justify-evenly">
                    <a
                      href={person.link}
                      target="_blank"
                      rel="noreferrer"
                      className="self-start mt-4 text-xl animate-none hover:animate-pulse"
                    >
                      {/* {shareIcon}{' '} */}
                    </a>
                    <a
                      href={person.link}
                      target="_blank"
                      rel="noreferrer"
                      className="self-start mt-4 text-xl animate-none hover:animate-pulse"
                    >
                      {/* {githubIcon}{' '} */}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Team;
