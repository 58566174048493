// import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Link } from 'react-router-dom';
const RenderMenuList = ({ icons }) => {
  // const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  return (
    <div className="flex flex-col text-xl lg:w-full lg:ml-52 lg:flex-row gap-y-10 lg:gap-y-0 lg:gap-5 lg:text-lg">
      <Link to={'/teams'} rel="noreferrer" className="list-none">
        {icons && icons[2]} Team
      </Link>
      {/* {isAuthenticated ? (
        <>
          <span>{user.name.split('@')[0]}</span>
          <Link
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            LogOut
          </Link>
        </>
      ) : (
        <Link
          to={'/#'}
          rel="noreferrer"
          className="list-none text-orangish"
          onClick={() => loginWithRedirect()}
        >
          {icons && icons[0]} Enter
        </Link>
      )} */}
    </div>
  );
};

export default RenderMenuList;
