import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import RenderMenuList from './RenderMenuList';
import {
  faCoins,
  faHouse,
  faListCheck,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

const crossIcon = <FontAwesomeIcon icon={faXmark} />;

const homeIcon = <FontAwesomeIcon icon={faHouse} />;
const aboutUsIcon = <FontAwesomeIcon icon={faUser} />;
const featuresIcon = <FontAwesomeIcon icon={faListCheck} />;
const solutionIcon = <FontAwesomeIcon icon={faCoins} />;

const portal = document.getElementById('portal');
const menuIcons = [homeIcon, aboutUsIcon, featuresIcon, solutionIcon];

const ShowPortal = ({ windowSize, setWindowSize, showMenu, setShowMenu }) => {
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
      if (windowSize >= 850) {
        setShowMenu(false);
      }
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  return createPortal(
    <div
      className={`fixed navs top-0 left-0 right-0 bottom-0 z-100 bg-gradient-to-b from-slate-900 to-slate-700  bg-opacity-95 overflow-y-hidden
      w-screen h-screen text-white  transition-all ease-in-out delay-400 inset-0 z-10`}
    >
      <div className="absolute flex justify-between w-11/12 h-screen p-10 text-2xl transition-all z-100 ">
        <div className="flex flex-col gap-10 lists">
          <RenderMenuList icons={menuIcons} />
        </div>
        <span
          onClick={() => {
            setShowMenu(false);
            portal.classList.remove('active');
          }}
          className="flex items-center justify-center w-10 h-10 p-2 text-black transition-all duration-300 rounded-lg cursor-pointer bg-gradient-to-b from-slate-400 to-slate-50 hover:shadow-xl hover:rotate-90"
        >
          {crossIcon}
        </span>
      </div>
    </div>,
    document.getElementById('portal')
  );
};
export default ShowPortal;
