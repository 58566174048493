import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
// import Tagline from '../components/Tagline';
import mainImage from '../assets/images/mainImage.png';
import SimulationsTable from './SimulationsTable';
import CodeView from './CodeView';
const imageProp = 'md:w-2/5   transition-all duration-1000';
const LandingPageMain = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <main className="relative items-center w-screen p-10 overflow-hidden md:p-20 md:flex md:justify-between ">
      {isAuthenticated ? (
        <div class="grid grid-cols-9 gap-4">
            <div className={` ${imageProp} `} class="col-span-1">
            <img
              className="object-cover w-full rounded-3xl opacity-95 "
              src={mainImage}
              alt=""
              srcSet="" />
          </div>
          <div class="col-span-3">
            <SimulationsTable/>  
            </div>
          <div class="col-span-5 border-2"><CodeView/></div>
        </div>
      ) : (
        <><div className={` ${imageProp} `}>
            <img
              className="object-cover w-full h-full rounded-3xl opacity-95 "
              src={mainImage}
              alt=""
              srcSet="" />
          </div><div className="flex items-center justify-center my-24 mb-10 left md:w-3/5">
              {/* <Tagline textSize={'9xl'} /> */}
              <section className="text-lg leading-9 text-white md:pl-10 lg:pl-5 md:text-xl lg:text-2xl description ">
                Intelligent simulation, democratized.
                <br />
                For &nbsp;
                <span className="text-orangish">everyone.</span>
                <br />
                <br />

                Coming soon! Please contact us for more information or sign up for beta access. 
                <br />
                <br />
                Email: <span className="text-orangish">prakashm at alum.mit.edu</span>
                <br />
                <br />
                Subscribe to our Simulation and AI Weekly Newsletter&nbsp;
                 <a class="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="https://www.linkedin.com/newsletters/about-simulation-and-ai-7074813127401320448/">Here</a>.

              </section>
              {/* <Link to="/explore">
      <button className="px-5 py-3 my-10 text-black rounded-lg bg-gradient-to-t from-cyan-500 to-cyan-100">
        Explore Now
      </button>
    </Link> */}
            </div></>
      )}
      
      
    </main>
  );
};

export default LandingPageMain;
