import React from 'react';
import { useParams } from 'react-router-dom';
import { people } from '../assets/utils/people';
import Navbar from '../component/NavBar';

const Individual = () => {
  const params = useParams();
  const person = people.find((person) => person.id === +params.id);
  
  return (
    <div className="w-screen h-full min-h-screen text-white bg-greyish">
      <Navbar />

      <div className="flex flex-col items-center mt-5 personal-bio">
        {/* <h1 className="w-11/12 text-3xl text-orangish">{person.name}</h1> */}
        {/* <div className="w-11/12 my-5 h-0.5 bg-white"></div> */}
        <div className="flex flex-col items-center w-11/12 lg:flex-row">
          {/* <div className='flex flex-col items-center justify-center'> */}
          <img
            className="object-cover transition-all duration-500 ease-in-out w-96 h-96 "
            src={person.imageLink}
            alt={person.name}
          />
          {/* <div> </div> */}
          {/* </div> */}
          <div className="flex flex-col items-center lg:w-3/4">
            <h2 className="flex justify-start w-full my-3 text-3xl items-left ">
              <span>{person.name}, &nbsp; </span>
              <span className="text-orangish">{person.title}</span>
            </h2>
            {/* <h2 className="my-3 text-3xl text-white">{person.title}</h2> */}
            <p className="leading-loose tracking-wider text-gray-300">
              {person.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Individual;
