import React from 'react';
import NavBar from '../component/NavBar';
import './Team.css';

import { Link } from 'react-router-dom';
import { people } from '../assets/utils/people';

const Teams = () => {
  return (
    <>
      <NavBar />
      <div
        id="people"
        className="w-screen min-h-screen p-10 text-white lg:h-auto md:p-20 bg-greyish"
      >
        <h1 className="flex flex-wrap items-center w-full h-auto mb-10 text-5xl font-bold leading-tight magic drop-shadow-md bg-clip-text md:leading-snug text-clip">
          <h1>Team</h1>
        </h1>

        <div className="flex flex-col flex-wrap items-center justify-center lg:flex-row people gap-y-5 gap-x-5 ">
          {people.map((person, index) => {
            return (
              <Link
                to={`/teams/${person.id}`}
                className="flex flex-col items-center justify-center cursor-pointer"
              >
                <div className="flex flex-col items-start justify-center my-4 overflow-hidden w-80 h-80 rounded-xl shadow-slate-200 hover:shadow-xl hover:shadow-slate-800">
                  <img
                    src={person.imageLink}
                    alt=""
                    srcSet=""
                    className="object-cover w-full h-full transition-all duration-500 ease-in-out filter grayscale hover:scale-110 lg:object-cover"
                  />
                </div>
                <div className="text-xl text-white">
                  {/* <span className="text-orangish">{person.name.charAt(0)}</span> */}
                  {/* <span className="text-white">{person.name.substring(1)}</span> */}
                  {person.name}
                </div>
                <div className="text-xl text-orangish">{person.title}</div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Teams;
