import prakash from '../images/Prakash1.png';
import nerain from '../images/nerain.jpeg';
import marcellin from '../images/Marcellin1.png';
import saugat from '../images/Saugat1.png';
// import rajSarraf from '../images/RajSaraf.jpg';
import rajSarraf from '../images/RajSarraf.jpg';
export const people = [
  {
    id: 1,
    imageLink: prakash,
    name: 'Prakash Manandhar',
    title: 'Co-Founder & CEO/CTO',
    // socialLink
    description: `Prakash has a multidisciplinary background with a Ph.D. in Bioengineering, an MBA, and MS. in Engineering System Design and Management. He has worked in various capacities in the engineering industry in the last two decades, starting in software engineering and then transitioning to medical device engineering, consumer electronics, and artificial intelligence. He has publications in medical imaging, ultrasonic surgical devices, flexible electronics, and multidisciplinary optimization. Dr. Manandhar holds patents in ultrasonic surgical instruments and artificial intelligence. He is affiliated with the Massachusetts Institute of Technology global teamwork lab, where he is researching agent-based modeling and simulation for optimizing and measuring engineering teamwork. In his various roles, including Head of Engineering at startups and Engineering Lead at established companies, he has championed using AI, simulation, and modeling to speed up engineering product development.\n On the fun side, Prakash is a fan of the city simulation and turn-based strategy genre of computer games.\n He is passionate about bringing agent-based and finite element modeling, simulation, and generative AI technology to speed up and democratize engineering product development.`,
  },
  {
    id: 3,
    imageLink: marcellin,
    name: 'Marcellin Feasson',
    title: 'Co-Founder & COO/CFO',
    description: `Marcellin studied physics at EPFL and ETH in Switzerland before joining the Harvard-Smithsonian Center for Astrophysics as a research fellow. He loves aeronautics and astronautics and wants to help humanity expand in the solar system and build next-generation aircrafts and spacecrafts. 

    In parallel with an advanced theoretical physics education, he got involved in different engineering projects. Along with Prakash, he joined the MIT team for a NASA competition to design and build an ice-mining robot for the Moon and Mars. They won the competition. 
    
    Two truths, one lie: he can recite Pulp Fiction’s Ezekiel 25:17, he knows 55 digits of π, he can solve a Rubik’s Cube in under 20 seconds.`,
  },
  {
    id: 4,
    imageLink: rajSarraf,
    name: 'Raj Sarraf',
    title: 'VP of Business Development',
    description: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Temporibus, dolor vel! Maiores perferendis aspernatur incidunt quam facilis. Quae fuga obcaecati accusantium non sunt mollitia amet dolores eum similique rerum? Tempore.`,
  },
  {
    id: 5,
    imageLink: saugat,
    title: 'Engineer',
    name: 'Saugat Poudel',
    description: `Saugat was introduced to programming (QBASIC) in grade 6 in the year 2012. Initially, he struggled a lot with the task of implementing mathematical equations and calculating area and perimeters. However, the idea of writing a program once and passing values to it to check his math answers fascinated him.

    Saugat kept at it, finding answers to his math questions without having to flip through the pages of his book. He gradually progressed from writing math functions to printing patterns and solving Fibonacci series. As the years passed, he transitioned from solving textbook problems to tackling real-world problems using programming.
    
    Besides programing, he is into music, movies, and travelling.`,
  },
  {
    id: 6,
    imageLink: nerain,
    title: 'Designer',
    name: 'Nerain Limbu',
    description: `Nerain is a talented UI/UX Designer who has built an impressive career in the field of user experience design. With a passion for creating beautiful, intuitive, and user-friendly digital products, Nerain has made a name for himself as a skilled designer with a keen eye for detail and a deep understanding of the principles of design.

    Nerain's journey as a designer began several years ago, when he developed an interest in the creative arts and technology. He decided to pursue his passion for design by enrolling in a design program at a leading university, where he gained a strong foundation in the principles of design, typography, color theory, and user-centered design.
    
    Over the years, Nerain has honed his skills and built an impressive portfolio of work, showcasing his ability to create engaging, intuitive, and visually stunning interfaces for a variety of digital products, including websites, mobile apps, and software applications. He is also skilled in creating wireframes, prototypes, and conducting user research and usability testing.
    
    Nerain is a versatile designer who is comfortable working in a fast-paced, collaborative environment. He is passionate about using design to solve complex problems, and he enjoys working closely with clients to understand their needs and deliver designs that exceed their expectations. He is also adept at using design tools such as Sketch, Figma, Adobe XD, and InVision, and he stays up-to-date with the latest design trends and best practices.
    
    In his free time, Nerain enjoys exploring new technologies and experimenting with different design techniques. He is also passionate about giving back to his community and volunteering his time to help mentor young designers and aspiring creatives`,
  },
];
