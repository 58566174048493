import React from 'react';
import LandingPageMain from '../component/LandingPageMain';
// import Navbar from '../component/NavBar';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-greyish">
      {/* <Navbar /> */}
      <LandingPageMain />
    </div>
  );
};

export default LandingPage;
