import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Team from './pages/Team';
import { Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import Teams from './pages/Teams';
import Individual from './pages/Individual';
import Payment from './component/Payment';
import Completion from './component/Completion';
const theme = createTheme({
  palette: {
    primary: {
      light: '#718792',
      main: '#455a64',
      dark: '#1c313a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});
const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENTID } = process.env;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={ REACT_APP_AUTH0_DOMAIN }
    clientId={ REACT_APP_AUTH0_CLIENTID }
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <React.StrictMode>
          <Routes>
            <Route path="/" exact element={<App />} />
            <Route path="/team" element={<Team />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/teams/:id" element={<Individual />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/completion" element={<Completion />} />
          </Routes>
        </React.StrictMode>
      </BrowserRouter>
    </ThemeProvider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
