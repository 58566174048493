import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/birdLogo.png';
const Banner = () => {
  return (
    <Link to="/">
      <div className="flex items-center w-3/5 my-3 text-2xl font-bold leading-tight logo md:leading-snug md:w-1/2">
        <img className="w-10 h-10" src={logo} alt="" srcSet="" />
        <>&nbsp;</>
        <span className="text-orangish">Sim </span>
        <span> ulate </span>
        <>&nbsp;</>
        <span className="text-orangish">Any</span>
        <span>thing </span>
        <>&nbsp;</>
        {/* <span>Inc</span> */}
      </div>
    </Link>
  );
};

export default Banner;
